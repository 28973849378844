@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .flex-center {
    @apply flex items-center justify-center;
  }
}
a {
  text-decoration: none !important;
}

.capitalize {
  text-transform: capitalize;
}

.ml-5 {
  margin-left: 5px;
}

.dashboard-item {
  border: 1px solid #f0f0f0;
  background-color: #fff;
  transition: all 0.3s;
  border-radius: 6px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-item:hover {
  background-color: #f5f5f5;
  transition: all 0.3s;
}

.react-transform-component,
.react-transform-wrapper {
  width: 100% !important;
  max-height: 400px;
}
